class OpalWoocommerceMain {
    static instance;

    static getInstance() {
        if (!OpalWoocommerceMain.instance) {
            OpalWoocommerceMain.instance = new OpalWoocommerceMain();
        }
        return OpalWoocommerceMain.instance;
    }

    constructor() {
        this.initCarousel();
        this.addedToCart();
        this.initWishlist();
        //this.initSlideProduct();
        this.initSelectOrder();
        this.renderWislistLoading();
        $(document).on('click', '.recently_viewed_product-item-remove', (event) => {
            this.removeProductView(event);
        })
    }

    removeProductView(event) {
        event.preventDefault();
        let $button = $(event.currentTarget);
        $.ajax({
            method: 'POST',
            url: efway_ajax_params.ajaxurl,
            data: {
                action: 'osf_product_view',
                productid: $button.data('productid'),
            },
            success: (response) => {
                $button.closest('.recently_viewed_product-item').remove();
            }
        })
    }

    initSelectOrder() {
        if ($('body').is('.post-type-archive-product, .tax-product_cat')) {

            let x, i, j, selElmnt, a, b, c;
            /*look for any elements with the class "custom-select":*/
            x = document.getElementsByClassName("woocommerce-ordering");
            let $select = $('.woocommerce-ordering .orderby');
            for (i = 0; i < x.length; i++) {
                selElmnt = x[i].getElementsByTagName("select")[0];
                /*for each element, create a new DIV that will act as the selected item:*/
                a = document.createElement("DIV");
                a.setAttribute("class", "select-selected");
                a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                x[i].appendChild(a);
                /*for each element, create a new DIV that will contain the option list:*/
                b = document.createElement("DIV");
                b.setAttribute("class", "select-items select-hide");
                for (j = 1; j < selElmnt.length; j++) {
                    /*for each option in the original select element,
                    create a new DIV that will act as an option item:*/
                    c = document.createElement("DIV");
                    c.innerHTML = selElmnt.options[j].innerHTML;
                    c.addEventListener("click", function (e) {
                        /*when an item is clicked, update the original select box,
                        and the selected item:*/
                        let y, i, k, s, h;
                        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                        h = this.parentNode.previousSibling;
                        for (i = 0; i < s.length; i++) {
                            if (s.options[i].innerHTML == this.innerHTML) {
                                s.selectedIndex = i;
                                h.innerHTML = this.innerHTML;
                                y = this.parentNode.getElementsByClassName("same-as-selected");
                                for (k = 0; k < y.length; k++) {
                                    y[k].removeAttribute("class");
                                }
                                this.setAttribute("class", "same-as-selected");
                                break;
                            }
                        }
                        h.click();
                        $select.trigger('change');
                    });
                    b.appendChild(c);
                }
                x[i].appendChild(b);
                a.addEventListener("click", function (e) {
                    /*when the select box is clicked, close any other select boxes,
                    and open/close the current select box:*/
                    e.stopPropagation();
                    closeAllSelect(this);
                    this.nextSibling.classList.toggle("select-hide");
                    this.classList.toggle("select-arrow-active");
                });
            }

            function closeAllSelect(elmnt) {
                /*a function that will close all select boxes in the document,
                except the current select box:*/
                let x, y, i, arrNo = [];
                x = document.getElementsByClassName("select-items");
                y = document.getElementsByClassName("select-selected");
                for (i = 0; i < y.length; i++) {
                    if (elmnt == y[i]) {
                        arrNo.push(i)
                    } else {
                        y[i].classList.remove("select-arrow-active");
                    }
                }
                for (i = 0; i < x.length; i++) {
                    if (arrNo.indexOf(i)) {
                        x[i].classList.add("select-hide");
                    }
                }
            }

            /*if the user clicks anywhere outside the select box,
            then close all select boxes:*/
            document.addEventListener("click", closeAllSelect);
        }
    }

    initSlideProduct() {
        $('.product-block .product-img-wrap').each((index, element) => {
            let $slider = $(element);
            let $container = $slider.closest('.product-block');
            $slider.flexslider({
                selector: '.inner > .product-image',
                animation: 'slide',
                controlNav: true,
                directionNav: true,
                slideshow: false,
                smoothHeight: false,
                before: (slider) => {

                    if (slider.direction === 'next') {
                        this.activeGalleryThumbnail($container, slider.currentSlide + 1);
                    } else {
                        this.activeGalleryThumbnail($container, slider.currentSlide - 1);
                    }
                },
                after: (slider) => {
                    this.activeGalleryThumbnail($container, slider.currentSlide);
                },
                start: function () {
                    setTimeout(function () {
                        $slider.addClass('flexslider-loaded');
                        $slider.data('flexslider').resize();
                    }, 500);
                }
                // 'animationSpeed': 500,
            });
            $('.gallery-nav-next', $container).on('click', function () {
                $container.find('.flex-nav-next a').trigger('click');
            });

            $('.gallery-nav-prev', $container).on('click', function () {
                $container.find('.flex-nav-prev a').trigger('click');
            });

            $('.gallery_item', $container).on('click', (event) => {
                let number = $(event.currentTarget).data('number');
                $slider.data('flexslider').flexAnimate(number);
                this.activeGalleryThumbnail($container, number);
            })
        })
    }

    activeGalleryThumbnail($container, key) {
        let $gallery = $('.woocommerce-loop-product__gallery', $container);
        $('.gallery_item', $gallery).removeClass('active');
        $('.gallery_item[data-number="' + key + '"]', $gallery).addClass('active');
    }

    initVariableItem() {
        $('.otf-wrap-swatches .swatch').on('click', (event) => {
            event.preventDefault();
            let $item = $(event.currentTarget);
            let $container = $item.closest('.product');

            if ($item.hasClass('checked')) {
                $container.removeClass('product-swatched');
                $item.removeClass('checked');
                $container.find('.product-image-swatch').remove();
            } else {
                $item.closest('.otf-wrap-swatches').find('.swatch').removeClass('checked');
                $container.addClass('product-swatched product-swatch-loading');
                $item.addClass('checked');
                let object = $item.data('image');
                if (object.src) {
                    let $img = $('<img />').on('load', (event) => {
                        $container.find('.product-image-swatch').remove();
                        $container.removeClass('product-swatch-loading');
                        $container.find('.product-image').first().before(`<div class="product-image-swatch">${event.currentTarget.outerHTML}</div>`);
                    }).attr({
                        src: object.src,
                    });
                    if (object.srcset) {
                        $img.attr(srcset, object.srcset);
                    }
                }
            }
        })
    }

    initCarousel() {
        $('.woocommerce-product-carousel').each((index, element) => {
            let $element = $(element);
            let items = $element.data('columns');
            let itemMobile = 1;
            let items768 = 2;
            let items980 = items;
            if (items > 3) {
                items980 = items - 1;
                items768 = items - 2;
            }
            if (items < 2) {
                items768 = items;
            }
            $element.find('ul.products').owlCarousel({
                items: items,
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: itemMobile,
                    },
                    480: {
                        items: itemMobile,
                    },
                    768: {
                        items: items768
                    },
                    980: {
                        items: items980
                    },
                    1200: {
                        items: items
                    }
                }
            })
        });
    }

    addedToCart() {
        let $product;
        $('body').on('adding_to_cart', function (event, button) {
            $product = button.closest('.product');
        }).on('added_to_cart', function () {
            let src = $product.find('img').first().attr('src');
            let productName = $product.find('.woocommerce-loop-product__title').text();
            let template = wp.template('added-to-cart-template');
            $('#page-title-bar').after(template({src: src, name: productName}));
            setTimeout(function () {
                $('.notification-added-to-cart').addClass('hide');
            }, 3000);
        });
    }

    initWishlist() {
        $(document).on('added_to_wishlist removed_from_wishlist', function () {
            let $wishlist = $('.opal-header-wishlist');
            $.ajax({
                url: yith_wcwl_l10n.ajax_url,
                data: {
                    action: 'osf_update_wishlist_count'
                },
                dataType: 'json',
                success: function (data) {
                    $wishlist.find('.count').text(data.count);
                },
                beforeSend: function () {
                },
                complete: function () {
                }
            })
        })
    }

    renderWislistLoading() {
        $('li.product .yith-wcwl-add-button .add_to_wishlist').after("<div class='ajax-loading opal-loading-wislist' style='visibility: hidden'></div>");
    }
}

